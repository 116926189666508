import { styled } from "styled-components";
import { SettingsFormStandard } from "../../../styled/forms/SettingsFormStandard";
import {
  FormButton,
  PasswordBox,
  TextFieldOutlined,
} from "../../../styled/input/Input";

const SecurityManage = ({ user }) => {
  const { userDetails, setUserDetails } = user;
  return (
    <>
      <SecuritySettingsStandard>
        <SettingsFormStandard>
          <div className="form">
            <div className="section">
              <div className="section_intro">
                <p className="label">Security</p>
                <p className="subtext">
                  To perform actions like change user password, change user
                  email or delete account, you have to log in to the user's
                  account. The user's details are shown below:
                </p>
              </div>

              <div className="form_item">
                <p className="label">User Email</p>
                <div className="content">
                  <TextFieldOutlined className="variant">
                    <input
                      type="email"
                      placeholder={userDetails?.email}
                      value={userDetails?.email}
                      disabled
                      // onChange={handleEmailAddress}
                    />
                  </TextFieldOutlined>
                </div>
              </div>

              <div className="form_item">
                <p className="label">User password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="text"
                      placeholder={userDetails?.userPass}
                      value={userDetails?.userPass}
                      disabled
                    />
                  </PasswordBox>
                </div>
              </div>

              {/* <div className="form_item">
                <p className="label">New password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="text"
                      placeholder="Password (Min. of 6 characters)"
                    />
                  </PasswordBox>
                </div>
              </div> */}

              {/* <div className="form_item">
                <p className="label">Confirm new password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input type="text" placeholder="Retype your password" />
                  </PasswordBox>
                </div>
              </div> */}

              {/* <FormButton>
                <p>Save</p>
              </FormButton> */}
            </div>

            {/* <div className="section">
              <div className="section_intro">
                <p className="title">Delete account</p>
                <p className="subtext">
                  Once you delete your account, there is no going back. Please
                  be certain.
                </p>
              </div>

              <div className="form_item">
                <div className="content">
                  <button className="delete_button">Delete your account</button>
                </div>
              </div>
            </div> */}
          </div>
        </SettingsFormStandard>
      </SecuritySettingsStandard>
    </>
  );
};

const SecuritySettingsStandard = styled.div`
  .form_item {
    margin-top: 24px;
  }

  .multi_factor {
    margin-top: 48px;
  }
`;

export default SecurityManage;
