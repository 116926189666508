import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFHneosyStYsJAK1FnrV3IvPCJkKS3XV8",
  authDomain: "overthetoplabs.firebaseapp.com",
  projectId: "overthetoplabs",
  storageBucket: "overthetoplabs.appspot.com",
  messagingSenderId: "116693263171",
  appId: "1:116693263171:web:0c2b5b48b23e3d3d6705f6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
